.HomePage {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to right, #c4d8e5, #49a2de);
  margin: 0;
  position: relative;
  overflow: hidden;
  color: #404040;
  font-family: 'Montserrat', sans-serif;
}
/* @media only screen and (max-width: 1200px) {
  .HomePage {
    overflow: scroll;
  }
} */

.people {
  position: absolute;
  bottom: 0;
  left: -10rem;
  width: 75%;
}
@media only screen and (max-width: 1000px) {
  .people {
    left: 4rem;
    bottom: 0;
  }
}

#Homerow {
  display: flex;
  align-items: center;
  height: 100vh;
}
