.Thankyou {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to right, #c4d8e5, #49a2de);
  margin: 0;
  position: relative;
  overflow: hidden;
  color: #404040;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.people {
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 50%;
}
.Thankyou h2 {
  margin: 0;
  padding: 10px;
}
