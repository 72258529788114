#suggestion-intro {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#suggestion-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-side {
  display: flex;
  flex-direction: column;
  text-align: left;
}
@media only screen and (max-width: 1200px) {
  .left-side {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.left-side h1 {
  margin-top: 0;
  text-align: left;
  font-family: 'Anton', sans-serif;
  font-size: 6rem;
  margin-bottom: 0;
  /* background-image: linear-gradient(to right, #49a2de, #25516f);
  color: white;
  padding: 1rem;
  border-radius: 50px; */
}
@media only screen and (max-width: 1200px) {
  .left-side h1 {
    text-align: center;
    font-size: 4rem;
  }
}

.left-side span {
  color: white;
  font-weight: 900;
}

.right-side {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .right-side {
    text-align: center;
  }
}

.right-side p {
  font-family: 'Anton', sans-serif;
  font-size: 1.4rem;
}

.title-content {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1000px) {
  .title-content {
    text-align: center;
  }
}
.title-content p {
  font-size: 1.3rem;
  padding: 0;
  margin: 0;
}

.small {
  font-size: 0.8rem;
}
.form-group label {
  color: #404040;
}

.title-content .platform {
  font-size: 2rem;
}

.title-content .question {
  font-size: 1.8rem;
  color: #404040;
  font-weight: bold;
  margin-top: -20px;
  padding: 0;
}

.title-content .advice {
  margin-top: 1rem;
}
